import React, { Component } from 'react';
import {  Box } from "@mui/material"

import './Fonts.css'
import './Footer.css'

export class Footer extends Component {
  static displayName = Footer.name;

  render() {
    return (
        <Box className="footerBox">
            <p>Les accès aux plateformes des accès précoces du laboratoire VERTEX Pharmaceuticals sont sécurisés et nécessitent des codes de connexion. Cliquez sur le lien suivant pour accéder aux <a target="_blank" href="https://www.vrtx.com/fr-fr/confidentialite/?lang=fr">mentions légales de VERTEX</a>.</p>
        </Box>
    )
  }
}