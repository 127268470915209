import React, { Component } from 'react';
import { Box, Card, CardContent, Grid, Button, IconButton } from '@mui/material'
import Typography from '@mui/material/Typography';
import { LoginOutlined } from '@mui/icons-material';

import './Fonts.css'
import './Home.css'

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <>
                <Grid container className='cardContainer'>
                    <Grid item lg={6}>
                        <div className="clickableTitle" onClick={() => { window.location.href = 'https://ap-casgevy-tdt-public-signup.icta.fr/' }}>
                            <Box sx={{ minWidth: 150 }}>
                                <Card variant="outlined" className="aPCard">
                                    <CardContent>
                                        <Grid container spacing={0} class='titleGrid'>
                                            <Grid item xs={11}>
                                                <h1 class='aPTitle'>
                                                    AP CASGEVY TDT
                                                </h1> 
                                            </Grid>
                                            <Grid item xs={1}>
                                                <LoginOutlined className='loginIcon' />
                                            </Grid>
                                        </Grid>
                                        {/*<h2 class='aPSubtitle'>En monothérapie</h2>*/}
                                    </CardContent>
                                    <CardContent>
                                        <p class='aPDescription'>Traitement de la ß-thalassémie dépendante des transfusions (TDT) chez les patients âgés de 12 ans à 35 ans,
                                            éligibles à une greffe de cellules souches hématopoïétiques (CSH)
                                            et pour lesquels un donneur apparenté HLA (Antigène leucocytaire humain)-compatible n’est pas disponible.</p>
                                    </CardContent>
                                    <CardContent>
                                        <Grid container spacing={8} className='cardActions'>
                                            <Grid item xs={4} className='cardAction'>
                                                <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/casgevytdt/AVIS HAS.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>Avis HAS</Button>
                                            </Grid>
                                            <Grid item xs={4} className='cardAction'>
                                                <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/casgevytdt/AP CASGEVY TDT - PUT RD.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>PUT-RD</Button>
                                            </Grid>
                                            <Grid item xs={4} className='cardAction'>
                                                <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/casgevytdt/AP_CASGEVY_TDT_RCP.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonText'>RCP CASGEVY</Button>
                                            </Grid>
                                            <Grid item xs={4} className='cardAction'>
                                                <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/casgevytdt/AP_CASGEVY_TDT_note d information.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonNormalCase buttonText'>Notice d'information</Button>
                                            </Grid>
                                            <Grid item xs={4} className='cardAction'>
                                                <Button variant='outlined' size="large" onClick={(event) => { window.open('/documents/casgevytdt/AP_CASGEVY_TDT_questionnaires_version 17JAN2024.pdf', '_blank'); event.stopPropagation(); }} className='buttonAction buttonNormalCase buttonText'>Questionnaire de qualité de vie</Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Box>
                        </div>
                    </Grid>
                </Grid>



            </>
        );
    }
}
